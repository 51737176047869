import React, { useEffect } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

import SocialIcons from '../../common/SocialIcons'

const Footer = () => {
	let images = useStaticQuery(graphql`{
  bannerEspa: file(relativePath: {eq: "e-bannerespa.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 350, layout: CONSTRAINED)
    }
  }
	bannerAlma: file(relativePath: {eq: "alma-banner.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 350, layout: CONSTRAINED)
    }
  }
}
`)

	useEffect(() => {
		document.getElementById('footer_year').innerText = new Date().getFullYear()
	}, [])

	return (
		<footer>
			<div className="container">
				<div className="footer__bottom">
					<div className="row align-items-center">
						<div className="col-12 d-flex flex-wrap align-items-center">
							<div className="mx-auto mx-lg-0">
								<SocialIcons type={'theme'} position={'relative'} espa={false} />
							</div>
							<div className="d-flex flex-wrap align-items-center justify-content-center mx-auto">
								<a href="/espa-web.pdf" target="_blank">
									<GatsbyImage
										image={images.bannerEspa.childImageSharp.gatsbyImageData}
										alt="ΕΣΠΑ"
										className="footer__banner--espa m-2" />
									</a>
								<a href="/alma-perigrafi.pdf" target="_blank">
									<GatsbyImage
										image={images.bannerAlma.childImageSharp.gatsbyImageData}
										alt="ΑΛΜΑ ΕΣΠΑ"
										className="footer__banner--espa m-2"
									/>
								</a>
							</div>
							<div className="mx-auto mx-lg-0">
								<p className="footer__copyright">© Copyright 2011 - <span id="footer_year">201*</span>. All Rights Reserved  <br /> Powered by <Link to="/en/">Think Plus - Creative &amp; Software Agency</Link><br /><Link to="/en/privacy-policy/">Privacy Policy</Link></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer
