import React from 'react'
import SocialIcons from '../common/SocialIcons'
import { GatsbyImage } from "gatsby-plugin-image";

import '../../styles/components/hero-photo.scss'

class HeroPhoto extends React.Component {
  render() {
    return (
      <article className="hero-photo dec-section--box-top">
        <div className="container">
          <div className="row">
            <div className="col">
                <SocialIcons type={"light"} position={"relative"} />
                <div className="hero__header">
                  <h1>{this.props.headerTitle}</h1>
                  {this.props.headerSubtitle && <p>{this.props.headerSubtitle}</p>}
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <GatsbyImage image={this.props.ctaImage} loading="auto" alt="" />
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default HeroPhoto