module.exports = {
  aggelos: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Aggelos Konstantopoulos",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/aggelos.jpg",
    "jobTitle": "Co-founder",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  david: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "David Trakatzidis",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/david.jpg",
    "jobTitle": "Co-founder & CEO",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
	agathi: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Agathi Pantechi",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/agathi-pantechi.jpg",
    "jobTitle": "Accounting Manager",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  kim: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Kim Schuller",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/kim.jpg",
    "jobTitle": "Marketing Director",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  eleanna: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Eleanna Sotiriou",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/ele.jpg",
    "jobTitle": "Senior Art Director",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  gpapageorgiou: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "John Papageorgiou",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/giannis-papageorgiou.jpg",
    "jobTitle": "Visual designer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  georgia: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Georgia Gerokosta",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/gio.jpg",
    "jobTitle": "Content Marketing Manager",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  stauros: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Stauros Nikolas",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/stauros.jpg",
    "jobTitle": "Creative Project Manager",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  andreas: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Andreas Dizis",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/andy.jpg",
    "jobTitle": "Head Photographer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  panos: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Panos Makris",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/panos-makris.jpg",
    "sameAs": [
      "https://www.linkedin.com/in/panos-makris-8aa02a27/",
      "https://github.com/panosmakris",
      "https://optimistic-volhard-c69d07.netlify.com/"
    ],
    "jobTitle": "Performance Marketing Manager",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  john: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "John Mantas",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/john.jpg",
    "sameAs": [
      "https://www.linkedin.com/in/john-mantas/",
      "https://johnmantas.com",
      "https://github.com/john-mantas",
      "https://codepen.io/john-mantas/"
    ],
    "jobTitle": "Software Engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  christos: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Christos Andreou",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/chris.jpg",
    "sameAs": [
      "https://www.linkedin.com/in/christos-andreou-080a1a105",
      "https://github.com/xristosAdnre0u"
    ],
    "jobTitle": "Software Engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  euaggelos: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Euaggelos Kolaitis",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/euaggelos-kolaitis.jpg",
    "jobTitle": "Senior Software Engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  mandreakis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Manos Andreakis",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/manos-andreakis.jpg",
    "jobTitle": "Visual designer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  vmarkou: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Vana Markou",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/vana-markou.jpg",
    "jobTitle": "Vibe manager",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  amichalopoulos: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Aggelos Michalopoulos",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/angelos-michalopoulos.jpg",
    "jobTitle": "Senior software engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  prellos: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Petros Rellos",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/petros-rellos.jpg",
    "jobTitle": "Senior software engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  glouvaris: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "George Louvaris",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/george-louvaris.jpg",
    "jobTitle": "Photographer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  cgkinis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Christos Gkinis",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/christos-gkinis.jpg",
    "jobTitle": "Videographer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  akoutroulis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Andreas Koutroulis",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/akoutroulis.jpg",
    "jobTitle": "Software engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  dkousoulis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Dimitris Kousoulis",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/dkousoulis.jpg",
    "jobTitle": "Creative director",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  dpantazopoulos: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Dionysis Pantazopoulos",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/dpantazopoulos.jpg",
    "jobTitle": "Technical lead",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  epanagiotidis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Eythimios Panagiotidis",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/epanagiotidis.jpg",
    "jobTitle": "Software engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  gbessis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Giorgos Bessis",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/gbessis.jpg",
    "jobTitle": "Media director",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  gmantis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Grigoris Mantis",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/gmantis.jpg",
    "jobTitle": "Videographer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  jgiannousis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Nikos Makris",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/jgiannousis.jpg",
    "jobTitle": "Software engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  kfilios: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Konstantinos Filios",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/kfilios.jpg",
    "jobTitle": "VP of engineering",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  nchorinopoulou: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Nicol Chorinopoulou",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/nchorinopoulou.jpg",
    "jobTitle": "Visual designer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  smichalas: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Stephanos Michalas",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/smichalas.jpg",
    "jobTitle": "Software engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  sstamoulis: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Stefanos Stamoulis",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/sstamoulis.jpg",
    "jobTitle": "Software engineer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
  szouzaneas: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Stratos Zouzaneas",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/szouzaneas.jpg",
    "jobTitle": "Videographer",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
	amarouli: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Alexandra Marouli",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/alexandra-marouli.jpg",
    "jobTitle": "Digital Account Manager",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
	stheodosopoulou: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Stela Theodosopoulou",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/stela-theodosopoulou.jpg",
    "jobTitle": "Senior Copywriter",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  },
	thgennimata: {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": "Theodora Gennimata",
    "url": "https://think-plus.gr/about-us/",
    "image": "https://think-plus.gr/images/team/theodora-gennimata.jpg",
    "jobTitle": "Copywriter",
    "worksFor": {
      "@type": "Organization",
      "name": "Think Plus - Advertising"
    }
  }
}