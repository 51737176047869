import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

import Footer from '../../en/common/footer'
import LocaleSelector from '../../common/LocaleSelector'

import '../../../styles/app.scss'
import '../../../styles/layout/alternate.scss'

/**
* Main layout component
**/
const DefaultLayout = ({ children, bodyClass, viewport }) => {
    
    useEffect(() => {
        const SITE_HEAD = document.querySelector('.site-head')

        document.addEventListener('keyup', (e) => {
            if (e.key == "Escape" ) {
                document.getElementById('site_nav').classList.contains('is-open') && toggleNav()
            }
        })
        document.addEventListener('scroll', ()=>{
            setTimeout(() => {
                window.pageYOffset > 1 ? SITE_HEAD.classList.add('site-head--backdrop') : SITE_HEAD.classList.remove('site-head--backdrop')
                if (viewport=='full') {
                window.pageYOffset > 500 ? SITE_HEAD.classList.remove('site-head--transparent') : SITE_HEAD.classList.add('site-head--transparent')
                }
            }, 200)
        })
    })

    let toggleNav = () => {
        let siteNav = document.getElementById('site_nav')
        setTimeout(()=>{
            siteNav.classList.toggle('is-open')
            document.querySelector('.site-head').classList.toggle('nav-is-open')
        }, 10)
        siteNav.classList.contains('is-open')
            ?setTimeout(()=>{
                siteNav.scrollTop = 0
                siteNav.style.display="none"
                },210)
            :siteNav.style.display="block"
        document.getElementById('site_nav_toggle').classList.toggle('is-pressed')
        siteNav.getAttribute('aria-hidden')=='true'?siteNav.setAttribute('aria-hidden', 'false'):siteNav.setAttribute('aria-hidden', 'true')
        siteNav.hidden?siteNav.hidden=false:siteNav.hidden=true
    };
    
    return (
    <>
        <Helmet>
            <html lang="en" />
            <body className={`site-alternate ${bodyClass ? bodyClass : ''}`} />
        </Helmet>

        <div className={viewport?`viewport ${viewport}`:'viewport'}>
            <div className="viewport-top">
                <header className="site-head">
                    <div className="container dec-site-quote">
                        <div className="row justify-content-center">
                            <div className=" col-6 col-lg-2">
                                <Link to="/en/">
                                <div className="site-logo"><p className="visually-hidden">Think Plus</p></div>
                                </Link>
                            </div>
                            <div className="col-6 col-lg-10 d-flex align-items-center justify-content-end">
                                <ul className="site__quick-nav horizontal-list">
                                    <li><Link to="/en/shopify-development/" activeClassName="link--active">Shopify</Link></li>
                                    <li><Link to="/en/video-production/" activeClassName="link--active">Video Production</Link></li>
                                    <li><Link to="/en/mailchimp-certified-experts-athens-greece/" activeClassName="link--active">Email Marketing</Link></li>
                                    <li><Link to="/en/our-work/" activeClassName="link--active">Our Work</Link></li>
                                    <li><Link to="/en/about-us/" activeClassName="link--active">About Us</Link></li>
                                    <li><Link to="/en/contact-us/" activeClassName="link--active">Contact Us</Link></li>
                                </ul>
                                <LocaleSelector />
                                <button className="site-nav__toggle" id="site_nav_toggle" onClick={toggleNav} aria-haspopup="true" aria-label="Menu">
                                    <div className="site-nav__bar"></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </header>
                <nav className="site-nav" id="site_nav" aria-label="Main Navigation" aria-hidden="true" hidden>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <Link to="/en/shopify-development/" className="site-nav-item" activeClassName="link--active">Shopify</Link>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <Link to="/en/video-production/" className="site-nav-item" activeClassName="link--active">Video Production</Link>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <Link to="/en/mailchimp-certified-experts-athens-greece/" className="site-nav-item" activeClassName="link--active">Email Marketing</Link>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <Link to="/en/our-work/" className="site-nav-item" activeClassName="link--active">Our Work</Link>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <Link to="/en/about-us" className="site-nav-item" activeClassName="link--active">About Us</Link>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <Link to="/en/contact-us/" className="site-nav-item" activeClassName="link--active">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </nav>

                <main className="site-main">
                    {children}
                </main>

            </div>

            <div className="viewport-bottom">
                <Footer />
            </div>
        </div>
    </>
    )
}

export default DefaultLayout
