import React from 'react'
import Helmet from 'react-helmet'
import { GatsbyImage } from "gatsby-plugin-image";

import '../../styles/components/team-member.scss'

class TeamMember extends React.Component {
  render() {
    return <>
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(this.props.memberSchema)}</script>
    </Helmet>
      <div className="team__card">
        <GatsbyImage
          image={this.props.memberPhoto}
          alt={this.props.memberName}
          className="team-card__photo" />
        <p>{this.props.memberName}<br />{this.props.memberTitle}</p>
      </div>
    </>;
  }
}

export default TeamMember