import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import Layout from '../../components/en/common/Layout'
import { MetaData } from '../../components/common/meta'
import HeroPhoto from '../../components/sections/hero-photo'
import OurServices from '../../components/en/sections/our-services'
import SectionHeader from '../../components/common/section-header'
import TeamMember from '../../components/common/team-member'
import ContactForm from '../../components/en/sections/contact-form'

import Slider from 'react-slick'

import '../../styles/pages/page-aboutus.scss'
import schema from '../../utils/schema/en/general'
import personSchema from '../../utils/schema/en/persons'

const pageAboutUs = ({ location }) => {
  const data = require('../../utils/schema/en/pages/aboutUs.json')
  let slider
  let settings = {
    dots: false,
    arrows:false,
    centerMode: true,
    centerPadding: '0',
    lazyload: "ondemand",
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "aboutus-office__slider",
    responsive: [
      {
        breakpoint: 544,
        settings: {
          vertical: true
        }
      }
    ]
  };

  let previous = () => {slider.slickPrev()}
  let next = () => {slider.slickNext()}

  let images = useStaticQuery(graphql`{
    hero: file(relativePath: {eq: "hero/hero-aboutus.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
    logoGrey: file(relativePath: {eq: "tp_logogrey.png"}) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    office1: file(relativePath: {eq: "aboutus/office/Office1.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 490, layout: CONSTRAINED)
      }
    }
    office2: file(relativePath: {eq: "aboutus/office/Office2.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 490, layout: CONSTRAINED)
      }
    }
    office3: file(relativePath: {eq: "aboutus/office/Office3.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 490, layout: CONSTRAINED)
      }
    }
    office4: file(relativePath: {eq: "aboutus/office/Office4.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 490, layout: CONSTRAINED)
      }
    }
    aggelos: file(relativePath: {eq: "aboutus/team/aggelos.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    andy: file(relativePath: {eq: "aboutus/team/andy.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    david: file(relativePath: {eq: "aboutus/team/david.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    ele: file(relativePath: {eq: "aboutus/team/ele.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    gio: file(relativePath: {eq: "aboutus/team/gio.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    john: file(relativePath: {eq: "aboutus/team/john-mantas.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    kim: file(relativePath: {eq: "aboutus/team/kim.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    stauros: file(relativePath: {eq: "aboutus/team/stauros.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    panos: file(relativePath: {eq: "aboutus/team/panos-makris.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    euaggelos: file(relativePath: {eq: "aboutus/team/euaggelos-kolaitis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    mandreakis: file(relativePath: {eq: "aboutus/team/manos-andreakis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    vmarkou: file(relativePath: {eq: "aboutus/team/vana-markou.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    amichalopoulos: file(
      relativePath: {eq: "aboutus/team/angelos-michalopoulos.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    prellos: file(relativePath: {eq: "aboutus/team/petros-rellos.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    glouvaris: file(relativePath: {eq: "aboutus/team/george-louvaris.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    cgkinis: file(relativePath: {eq: "aboutus/team/christos-gkinis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    akoutroulis: file(relativePath: {eq: "aboutus/team/andreas-koutroulis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    dkousoulis: file(relativePath: {eq: "aboutus/team/dimitris-kousoulis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    dpantazopoulos: file(
      relativePath: {eq: "aboutus/team/dionysis-pantazopoulos.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    epanagiotidis: file(
      relativePath: {eq: "aboutus/team/efthymios-panagiotidis.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    gbessis: file(relativePath: {eq: "aboutus/team/george-bessis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    gmantis: file(relativePath: {eq: "aboutus/team/grigoris-mantis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    jgiannousis: file(relativePath: {eq: "aboutus/team/john-giannousis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    kfilios: file(relativePath: {eq: "aboutus/team/konstantinos-filios.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    nchorinopoulou: file(relativePath: {eq: "aboutus/team/nicol-chorinopoulou.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    smichalas: file(relativePath: {eq: "aboutus/team/stefanos-michalas.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    sstamoulis: file(relativePath: {eq: "aboutus/team/stefanos-stamoulis.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
    szouzaneas: file(relativePath: {eq: "aboutus/team/stratos-zouzaneas.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
		agathi: file(relativePath: {eq: "aboutus/team/agathi-pantechi.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
		amarouli: file(relativePath: {eq: "aboutus/team/alexandra-marouli.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
		stheodosopoulou: file(relativePath: {eq: "aboutus/team/stela-theodosopoulou.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
		thgennimata: file(relativePath: {eq: "aboutus/team/theodora-gennimata.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 840, layout: CONSTRAINED)
      }
    }
  }
  `)
  const imageLogoGrey = getImage(images.logoGrey)
  const bgImageLogoGrey = convertToBgImage(imageLogoGrey)

  return <>
    <MetaData
      data={data}
      location={location}
      type="website"
      linkAlternate={false}
    />
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema.organization)}</script>
      <link rel="alternate" href="https://think-plus.gr/about-us/" hreflang="el-gr" />
      <link rel="alternate" href="https://think-plus.gr/en/about-us/" hreflang="en" />
    </Helmet>
    <Layout>
      <article className="page-aboutus">
        <HeroPhoto
          headerTitle={"About Us"}
          headerSubtitle={"Thinking about choosing us? Here are some things you should know about us."}
          ctaImage={images.hero.childImageSharp.gatsbyImageData}
        />
        <BackgroundImage
          Tag="section"
          className="aboutus__intro"
          {...bgImageLogoGrey}
        >
          <div className="dec-section--line-vertical">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-3 text-align-center">
                  <p>We believe that friends make the most beautiful things in the world. We’re a group of friends who love to make new content, create new websites, take photos, create unique projects, design perfect logos and code the programming language of success.</p>
                  <p>Our client’s vision becomes our own. So all our talents and expertise, are used to make that vision a reality.</p>
                  <p>All the projects we have implemented attest to our willingness to create unique results and our ability as a team to understand the particular requirements of each client. Our client’s success is a daily reward.</p>
                  <p>Alongside that, our distinctions and awards throughout the years, have “fueled” us to keep going and to strive for even better provided services!</p>
                  <strong>Think Plus because everything is possible with patience and perseverance!</strong>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
        <section className="aboutus__tools text-align-center">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="dec-header--underline">The Right Tools. The Right Solution.</h2>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p className="aboutus__tools-text">At Think Plus we design the right marketing strategy for you, determining the choice of objectives and the allocation of resources. Don't forget that marketing strategy is most effective when it is an integral part of a consistent strategy, determining whether the a business or brand name will successfully attract customers, potential buyers and competitors in the marketplace.</p>
              </div>
            </div>
          </div>
        <OurServices decoration={false} subtitle={true} />
        </section>
        <section className="aboutus__offices">
          <SectionHeader
            title={"OUR OFFICE"}
            text={"helps us think better!"}
          />
          <div className="container">
            <Slider ref={c => {slider = c}} {...settings}>
              <GatsbyImage image={images.office2.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={images.office3.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={images.office1.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={images.office4.childImageSharp.gatsbyImageData} alt="" />
            </Slider>
            <div className="row no-gutters">
              <div className="col text-align-right">
                <button className="aboutus-office__slider-prev" rel="prev" aria-label="Previous" onClick={previous}><div className="btn-triangle btn-triangle--left"></div></button>
              </div>
              <div className="col">
                <button className="aboutus-office__slider-next" rel="next" aria-label="Next" onClick={next}><div className="btn-triangle btn-triangle--right"></div></button>
              </div>
            </div>
          </div>
        </section>
        <section className="aboutus__stats">
          <div className="container">
            <div className="aboutus__stat">
              <p className="h1">200</p>
              <p className="dec-text__line--left">satisfied clients</p>
            </div>
            <div className="aboutus__stat">
              <p className="h1">8500</p>
              <p className="dec-text__line--left">cups of coffee</p>
            </div>
            <div className="aboutus__stat">
              <p className="h1">11000</p>
              <p className="dec-text__line--left">development hours</p>
            </div>
          </div>
        </section>
        <section className="aboutus__vision">
          <div className="container">
            <div className="row align-items-center vision--creative">
                <h2 className="dec-text__line--bottom">WE ARE<br /> MORE THAN CREATIVE</h2>
                <p>Additionally, our fresh ideas, innovation and development through continuous research for new technological applications and breakthrough marketing methods, assures that we are always a step ahead.</p>
            </div>
            <div className="row align-items-center vision--determined">
                <p className="text-align-right ">The satisfaction of our customers is the ultimate goal and it’s ensured through the 6 Think Plus principles: Synergy - Consistency - Update - Innovation - Efficiency - Uniqueness.</p>
                <h2 className="dec-text__line--top">WE ARE <br />MORE THAN DETERMINED</h2>
            </div>
          </div>
        </section>
        <section className="aboutus__team">
          <SectionHeader
            title={"OUR TEAM"}
            text={"We don't have a style.<br>We have standards."}
          />
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.aggelos.childImageSharp.gatsbyImageData}
                  memberName={'Aggelos Konstantopoulos'}
                  memberTitle={'co-founder'}
                  memberSchema={personSchema.aggelos}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.david.childImageSharp.gatsbyImageData}
                  memberName={'David Trakantzidis'}
                  memberTitle={'co-founder & ceo'}
                  memberSchema={personSchema.david}
                />
              </div>
							<div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.agathi.childImageSharp.gatsbyImageData}
                  memberName={'Agathi Pantechi'}
                  memberTitle={'accounting manager'}
                  memberSchema={personSchema.agathi}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.kim.childImageSharp.gatsbyImageData}
                  memberName={'Kim Schuller'}
                  memberTitle={'marketing director'}
                  memberSchema={personSchema.kim}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.ele.childImageSharp.gatsbyImageData}
                  memberName={'Eleanna Sotiriou'}
                  memberTitle={'art director'}
                  memberSchema={personSchema.eleanna}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.mandreakis.childImageSharp.gatsbyImageData}
                  memberName={'Manos Andreakis'}
                  memberTitle={'visual designer'}
                  memberSchema={personSchema.mandreakis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.nchorinopoulou.childImageSharp.gatsbyImageData}
                  memberName={'Nicol Chorinopoulou'}
                  memberTitle={'visual designer'}
                  memberSchema={personSchema.nchorinopoulou}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.dkousoulis.childImageSharp.gatsbyImageData}
                  memberName={'Dimitris Kousoulis'}
                  memberTitle={'creative director'}
                  memberSchema={personSchema.dkousoulis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.gbessis.childImageSharp.gatsbyImageData}
                  memberName={'George Bessis'}
                  memberTitle={'media director'}
                  memberSchema={personSchema.gbessis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.gio.childImageSharp.gatsbyImageData}
                  memberName={'Georgia Gerokosta'}
                  memberTitle={'digital account manager'}
                  memberSchema={personSchema.georgia}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.stauros.childImageSharp.gatsbyImageData}
                  memberName={'Stauros Nikolas'}
                  memberTitle={'digital account manager'}
                  memberSchema={personSchema.stauros}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.vmarkou.childImageSharp.gatsbyImageData}
                  memberName={'Vana Markou'}
                  memberTitle={'digital account manager'}
                  memberSchema={personSchema.vmarkou}
                />
              </div>
							<div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.amarouli.childImageSharp.gatsbyImageData}
                  memberName={'Alexandra Marouli'}
                  memberTitle={'digital account manager'}
                  memberSchema={personSchema.amarouli}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.kfilios.childImageSharp.gatsbyImageData}
                  memberName={'Konstantinos Filios'}
                  memberTitle={'vp of engineering'}
                  memberSchema={personSchema.kfilios}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.amichalopoulos.childImageSharp.gatsbyImageData}
                  memberName={'Aggelos Michalopoulos'}
                  memberTitle={'director of engineering'}
                  memberSchema={personSchema.amichalopoulos}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.dpantazopoulos.childImageSharp.gatsbyImageData}
                  memberName={'Dionysis Pantazopoulos'}
                  memberTitle={'technical lead'}
                  memberSchema={personSchema.dpantazopoulos}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.euaggelos.childImageSharp.gatsbyImageData}
                  memberName={'Euaggelos Kolaitis'}
                  memberTitle={'senior software engineer'}
                  memberSchema={personSchema.euaggelos}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.prellos.childImageSharp.gatsbyImageData}
                  memberName={'Petros Rellos'}
                  memberTitle={'senior software engineer'}
                  memberSchema={personSchema.prellos}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.john.childImageSharp.gatsbyImageData}
                  memberName={'John Mantas'}
                  memberTitle={'senior software engineer'}
                  memberSchema={personSchema.john}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.akoutroulis.childImageSharp.gatsbyImageData}
                  memberName={'Andreas Koutroulis'}
                  memberTitle={'software engineer'}
                  memberSchema={personSchema.akoutroulis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.jgiannousis.childImageSharp.gatsbyImageData}
                  memberName={'John Giannousis'}
                  memberTitle={'software engineer'}
                  memberSchema={personSchema.jgiannousis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.epanagiotidis.childImageSharp.gatsbyImageData}
                  memberName={'Efthymios Panagiotidis'}
                  memberTitle={'software engineer'}
                  memberSchema={personSchema.epanagiotidis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.smichalas.childImageSharp.gatsbyImageData}
                  memberName={'Stefanos Michalas'}
                  memberTitle={'software engineer'}
                  memberSchema={personSchema.smichalas}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.sstamoulis.childImageSharp.gatsbyImageData}
                  memberName={'Stefanos Stamoulis'}
                  memberTitle={'software engineer'}
                  memberSchema={personSchema.sstamoulis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.panos.childImageSharp.gatsbyImageData}
                  memberName={'Panos Makris'}
                  memberTitle={'performance marketing manager'}
                  memberSchema={personSchema.panos}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.andy.childImageSharp.gatsbyImageData}
                  memberName={'Andreas Dizis'}
                  memberTitle={'head photographer'}
                  memberSchema={personSchema.andreas}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.glouvaris.childImageSharp.gatsbyImageData}
                  memberName={'George Louvaris'}
                  memberTitle={'photographer'}
                  memberSchema={personSchema.glouvaris}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.cgkinis.childImageSharp.gatsbyImageData}
                  memberName={'Christos Gkinis'}
                  memberTitle={'video production manager'}
                  memberSchema={personSchema.cgkinis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.gmantis.childImageSharp.gatsbyImageData}
                  memberName={'Grigoris Mantis'}
                  memberTitle={'videographer'}
                  memberSchema={personSchema.gmantis}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.szouzaneas.childImageSharp.gatsbyImageData}
                  memberName={'Stratos Zouzaneas'}
                  memberTitle={'videographer'}
                  memberSchema={personSchema.szouzaneas}
                />
              </div>
							<div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.stheodosopoulou.childImageSharp.gatsbyImageData}
                  memberName={'Stela Theodosopoulou'}
                  memberTitle={'senior copywriter'}
                  memberSchema={personSchema.stheodosopoulou}
                />
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <TeamMember
                  memberPhoto={images.thgennimata.childImageSharp.gatsbyImageData}
                  memberName={'Theodora Gennimata'}
                  memberTitle={'copywriter'}
                  memberSchema={personSchema.thgennimata}
                />
              </div>
            </div>
          </div>
        </section>
        <ContactForm />
      </article>
    </Layout>
  </>;
}

export default pageAboutUs
