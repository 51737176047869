module.exports = {
  localBusiness: {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Think Plus - Advertising",
    "image": "https://think-plus.gr/images/tp-logoblack.svg",
    "@id": "",
    "url": "http://think-plus.gr/",
    "telephone": "+302106101478",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Amarousiou Chalandriou 36B,",
      "addressLocality": "Marousi",
      "postalCode": "15125",
      "addressCountry": "Greece",
      "addressRegion": "+30"
    },
    "areaServed": "Greece",
    "priceRange": "€€€-€€€€€",
    "sameAs": [
      "https://www.facebook.com/thinkplusadvertising",
      "https://www.instagram.com/thinkplus_advertising",
      "https://twitter.com/ThinkPlusAdvert",
      "https://www.youtube.com/channel/UCRERWJ-RkKXd17KMz2vyH4Q",
      "https://www.linkedin.com/company/think-plus-advertising/about/"
    ],
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
        ],
        "opens": "10:00",
        "closes": "18:00"
      }
    ]
  },
  organization: {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Think Plus - Advertising",
    "alternateName": "Think Plus Advertising",
    "url": "https://think-plus.gr",
    "logo": "https://think-plus.gr/images/tp-logoblack.svg",
    "areaServed": "Greece",
    "award": [
      "Gold Award at the E-volution Awards for e-socks.gr (\"Customer-oriented & Procedures\" - \"Personalized Services\")",
      "Silver Award at the E-volution Awards for Skroutz Application (\"Electronic Business Services & Infrastructure\" - \"Infrastructure Services\")",
      "Bronze Award at the E-Volution Awards for MEL (\"Digital Cross & Marketing\" - \"Best Cross Border Marketing\")",
      "Gold Award at the Franchise Awards 2018 (Best use of Technology - \"Smileworks\")",
      "Gold Award at the Franchise Awards 2018 (Most Innovative Concept - \"Smileworks\")",
      "Second Prize at Mobile Excellence Awards 2018 in the best Mobile Apps - \"Fitgate\"",
      "Gold Award at the Social Media Awards 2016 (Best use of social media in Real Life)",
      "Silver Award at the Social Media Awards 2016 \"Innovation in Social Media\"",
      "Gold Award at the Social Media Awards 2015 (Best use of social media in Real Life)",
      "First International Award for Best Facebook Activation for the years 2012, 2013 & 2014 (SPI Group)",
      "Two Social Media Awards 2013 Awards (Jägermeister & Stolichnaya)",
      "Second prize in the Greek \"Application marathon\" for the mobile application \"FaceMetrics\" under the auspices of the National Bank"
    ],
    "sameAs": [
      "https://www.facebook.com/thinkplusadvertising",
      "https://twitter.com/ThinkPlusAdvert",
      "https://www.instagram.com/thinkplus_advertising/?hl=el",
      "https://www.youtube.com/channel/UCRERWJ-RkKXd17KMz2vyH4Q",
      "https://www.linkedin.com/company/think-plus-advertising/about/"
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "2106101478",
      "contactType": "customer service",
      "contactOption": "TollFree",
      "areaServed": "GR",
      "availableLanguage": "Greek"
    }
  }
}